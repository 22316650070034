import React from "react";
import styled from "styled-components";
import { FaDownload, FaCloudDownloadAlt } from "react-icons/fa";

// 페이지 전체 레이아웃 설정
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #212225;
  font-family: "Poppins", sans-serif;
  color: #333;
  text-align: center;
`;

// 타이틀 스타일
const Title = styled.h1`
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #333;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1.5s ease-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

// 서브타이틀 (설명 텍스트) 스타일
const Subtitle = styled.p`
  font-size: 1.3rem;
  color: #555;
  margin-bottom: 40px;
  font-weight: 500;
  line-height: 1.5;
  max-width: 600px;
`;

// 다운로드 버튼 스타일
const DownloadButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;
  background: #ffc813;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 50px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  margin-top: 20px;
  position: relative;
`;

// 앱 컴포넌트
const App = () => {
  return (
    <Container>
      <DownloadButton href="/api/file/apk" download="chatt-app.apk">
        {"Download "} <FaCloudDownloadAlt />
      </DownloadButton>
    </Container>
  );
};

export default App;
